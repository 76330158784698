import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface OrderState {
    stripeToken: string | null
    userDetails?: OrderFormUserInput | null
    serviceDetails?: OrderFormServiceInput | null
    addStripeToken: (token: string | null) => void
    addUserDetails: (data: OrderFormUserInput) => void
    addServiceDetails: (data: OrderFormServiceInput) => void
    clearOrder: () => void
}

// Note: orderStore is for non-persistent data ie. user & payment info
export const useOrderStore = create<OrderState>()(
    persist(
        (set, get) => ({
            stripeToken: null,
            userDetails: null,
            serviceDetails: null,
            addStripeToken: (token: string | null) => {
                set((state) => ({ stripeToken: token }))
            },
            addUserDetails: (data: OrderFormUserInput) => {
                set((state: OrderState) => ({ userDetails: data }))
            },
            addServiceDetails: (data: OrderFormServiceInput) => {
                set((state: OrderState) => ({ serviceDetails: data }))
            },
            clearOrder: () => {
                // Only clear data which can cause bugs if user revisits cart
                set({ stripeToken: null, userDetails: null, serviceDetails: null })
            }
        }),
        {
            name: 'order-storage', // name of item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)
